import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Routes, Route, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Team from "./scenes/team";
import Form from "./scenes/form";
import TallyCompanies from "./components/Tally-Companies/TallyCompanies";
import PrimaryApprov from "./components/Voucher-Approval/primary-Approval";
import SecondaryApprov from "./components/Voucher-Approval/secondary-Approval";
import FinalApprov from "./components/Voucher-Approval/final-Approval";
import AdminList from "./components/Voucher-Approval/admin-Report";
import ApprovalModuleUsers from "./components/Approval-Module-Users/Approval-Module-Users"; 
import MarketPlace from "./components/Market_Place/MarketPlace";
import ModuleDetailPage from "./components/Market_Place/ModuleDetailPage";
import ResetPwd from "./authpages/ResetPassword"
import Forgotpassword from "./authpages/Forgotpassword";
import Signup from "./authpages/Signup";
import Login from "./authpages/Login";


import { Navigate } from 'react-router-dom';



function App() {
  const [theme, colorMode] = useMode();
  //stores the current url path string
  const location = useLocation();



  const isAuthenticated = (localStorage.getItem('token') !== null) ;


  const PrivateRoute = ({ element, isAuthenticated }) => {
    return isAuthenticated ? element : <Navigate to="/" />;
  };

  //Checks if the url includes the auth route
  const isAuthpage = ['/', '/signup', '/forgot-password'].includes(location.pathname) || location.pathname.includes('/reset-password');


  return (
    // For being able to use the use Memo function of the colorMode 
    <ColorModeContext.Provider value={colorMode}>
      {/* For providing the theme context */}
      <ThemeProvider theme={theme}>
        {/* For consistency across browsers */}
        <CssBaseline />
        <div className="app">
          {!isAuthpage && <Sidebar />}
          <main className="content">
            {!isAuthpage && <Topbar />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/forgot-password" element={<Forgotpassword />} /> 
              <Route path="/reset-password" element={<ResetPwd />} /> 

              <Route path="/Dashboard" element={<PrivateRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />} />
              <Route path="/team" element={<PrivateRoute element={<Team />} isAuthenticated={isAuthenticated} />} />
              <Route path="/form" element={<PrivateRoute element={<Form />} isAuthenticated={isAuthenticated} />} />
              <Route path="/TallyCompanies" element={<PrivateRoute element={<TallyCompanies />} isAuthenticated={isAuthenticated} />} />
              <Route path="/PrimaryApprov" element={<PrivateRoute element={<PrimaryApprov />} isAuthenticated={isAuthenticated} />} />
              <Route path="/SecondaryApprov" element={<PrivateRoute element={<SecondaryApprov />} isAuthenticated={isAuthenticated} />} />
              <Route path="/FinalApprov" element={<PrivateRoute element={<FinalApprov />} isAuthenticated={isAuthenticated} />} />
              <Route path="/AdminList" element={<PrivateRoute element={<AdminList />} isAuthenticated={isAuthenticated} />} />
              <Route path="/ApprovalModuleUsers" element={<PrivateRoute element={<ApprovalModuleUsers />} isAuthenticated={isAuthenticated} />} />
              <Route path="/MarketPlace" element={<PrivateRoute element={<MarketPlace />} isAuthenticated={isAuthenticated} />} />
              <Route path="/ModuleDetailPage/:id" element={<PrivateRoute element={<ModuleDetailPage />} isAuthenticated={isAuthenticated} />} />
              
            </Routes>

          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
