import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeIcon from "@mui/icons-material/Home";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ApprovalIcon from "@mui/icons-material/Approval";
import SecondApprov from "@mui/icons-material/HowToReg";
import VerifiedIcon from "@mui/icons-material/Verified";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddHomeWorkRoundedIcon from "@mui/icons-material/AddHomeWorkRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SourceIcon from "@mui/icons-material/Source";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import StoreIcon from "@mui/icons-material/Store";

import axios from "axios";
import { Co2Sharp } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");

  // State variables for visibility
  const [showPrimaryApproval, setShowPrimaryApproval] = useState(true);
  const [showSecondaryApproval, setShowSecondaryApproval] = useState(true);
  const [showFinalApproval, setshowFinalApproval] = useState(true);
  const [showAdminApproval, setshowAdminApproval] = useState(false);
  const [showUserConfigApproval, setshowUserConfigApproval] = useState(false);
  const [showAllUsers, setshowAllUsers] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/profile/approvalPerm",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const UserNameData = Object.values(response.data);

      const permissionLevels = UserNameData.map(
        (item) => item.permission_Level
      );

      const permissioncmpId = UserNameData.map((item) => item.cmp_id);

      if (
        permissionLevels.includes("Primary") &&
        permissioncmpId.includes(cmpid)
      ) {
        setShowPrimaryApproval(true);
      } else {
        setShowPrimaryApproval(false);
      }

      if (
        permissionLevels.includes("Secondary") &&
        permissioncmpId.includes(cmpid)
      ) {
        setShowSecondaryApproval(true);
      } else {
        setShowSecondaryApproval(false);
      }

      if (
        permissionLevels.includes("Final") &&
        permissioncmpId.includes(cmpid)
      ) {
        setshowFinalApproval(true);
      } else {
        setshowFinalApproval(false);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("Role");

    if (role === "admin") {
      setshowUserConfigApproval(true);
      setshowAllUsers(true);
      setshowAdminApproval(true);
    }
    fetchData();
  }, []);

  const role =
    localStorage.getItem("Role") === "admin"
      ? "Administartor"
      : localStorage.getItem("Role");
  const username = localStorage.getItem("username");
  //========================= get approval user permisions ================================================

  //============================================================================================

  const [isDataOpen, setIsDataOpen] = useState(false); // State to manage collapse
  const [isMarketPlace, setMarketPlace] = useState(false);
  const [isVchOpen, setIsVchOpen] = useState(false);
  const handleDataToggle = () => {
    setIsDataOpen(!isDataOpen); // Toggle the open state
  };
  const handlvchToggle = () => {
    setIsVchOpen(!isVchOpen); // Toggle the open state
  };

  const handlMarketToggle = () => {
    setMarketPlace(!isMarketPlace); // Toggle the open state
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          boxShadow: "1px 0px 5px rgba(0, 0, 0, 0.5)",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          // padding: "5px 35px 5px 20px !important",
          color: `${colors.grey[400]}!important`,
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
          backgroundColor: `${colors.blueAccent[900]}!important`, //"#e0e7ff !important",
          borderRadius: "30px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/*LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="../../assets/Shraddha Infinite Logo.png"
                  alt="Shraddha Infinite"
                  style={{
                    //maxWidth: "100%",
                    // maxHeight: "100%",
                    height: "80px",
                    width: "180px",
                  }}
                />
              </Box>
            )}
            <Divider style={{ marginTop: "15px" }} />
          </MenuItem>

          {/* Menu Items */}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <div
              style={{
                marginLeft: "0px",
                color: "#868dfb",
                alignItems: "left",
                justifyContent: "left",
              }}
            >
              <Item
                title={
                  <Typography
                    variant="h4"
                    style={{ color: colors.primary[100] }} // Set the desired color
                  >
                    Dashboard
                  </Typography>
                }
                to="/Dashboard"
                icon={<HomeIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                //marginTop:"15px"
              }}
              onClick={handleDataToggle}
            >
              <SourceIcon
                style={{ marginLeft: "25px", color: colors.primary[100] }}
              />
              {/* Add your icon here */}
              <Typography
                variant="h4"
                paddingLeft={isCollapsed ? "10%" : "0px"}
                color={colors.primary[100]}
                sx={{
                  m: "15px 0 5px 20px",
                  cursor: "pointer",
                }}
              >
                Data
              </Typography>

              {isDataOpen ? ( // Show up arrow when expanded
                <ExpandLessIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              ) : (
                // Show down arrow when collapsed
                <ExpandMoreIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              )}
            </div>
            {isDataOpen && ( // Conditionally render items
              <>
                <div
                  style={{
                    marginLeft: "20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Item
                    title="Tally Companies"
                    to="/TallyCompanies"
                    icon={<AddHomeWorkRoundedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  {showAllUsers && (
                    <Item
                      title="All Users"
                      to="/team"
                      icon={<PeopleOutlinedIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </div>
              </>
            )}

            {/* ======================= market place menu items ======================== */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlMarketToggle}
            >
              <StoreIcon
                style={{ marginLeft: "25px", color: colors.primary[100] }}
              />
              {/* Add your icon here */}
              <Typography
                paddingLeft={isCollapsed ? "10%" : "0px"}
                variant="h4"
                color={colors.primary[100]} // Ensure the text color is black
                sx={{
                  m: "15px 0 5px 20px",
                  cursor: "pointer",
                }}
              >
                Market Place
              </Typography>
              {isMarketPlace ? ( // Show up arrow when expanded
                <ExpandLessIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              ) : (
                // Show down arrow when collapsed
                <ExpandMoreIcon
                  style={{ marginLeft: "auto", color: colors.primary[100] }}
                />
              )}
            </div>
            {isMarketPlace && (
              <>
                <div
                  style={{
                    marginLeft: "20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Item
                    title="Module List"
                    to="/MarketPlace"
                    icon={<AddHomeWorkRoundedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlvchToggle}
            >
              <VerifiedIcon
                style={{ marginLeft: "25px", color: colors.primary[100] }}
              />
              <Typography
                paddingLeft={isCollapsed ? "10%" : "0px"}
                color={colors.primary[100]}
                sx={{ m: "15px 0 5px 20px", fontSize: "18.5px" }}
              >
                Voucher Approval
              </Typography>
              {isVchOpen ? ( // Show up arrow when expanded
                <ExpandLessIcon
                  style={{ marginRight: "auto", color: colors.primary[100] }}
                />
              ) : (
                // Show down arrow when collapsed
                <ExpandMoreIcon
                  style={{ marginRight: "auto", color: colors.primary[100] }}
                />
              )}
            </div>
            {isVchOpen && ( // Conditionally render items
              <>
                <div
                  style={{
                    marginLeft: "20px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {showPrimaryApproval && (
                    <Item
                      title="Primary Approval"
                      to="/PrimaryApprov"
                      icon={<ApprovalIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}

                  {showSecondaryApproval && (
                    <Item
                      title="Secondary Approval"
                      to="/SecondaryApprov"
                      icon={<SecondApprov />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {showFinalApproval && (
                    <Item
                      title="Final Approval"
                      to="/FinalApprov"
                      icon={<TaskAltIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {showAdminApproval && (
                    <Item
                      title="Admin Report"
                      to="/AdminList"
                      icon={<AssignmentIndIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                  {showUserConfigApproval && (
                    <Item
                      title="Users Config"
                      to="/ApprovalModuleUsers"
                      icon={<AccountCircleIcon />}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </div>
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
