import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": { color: theme.palette.text.primary },
  "& .MuiInputLabel-root.Mui-focused": { color: theme.palette.text.primary },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.text.primary,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: theme.palette.text.primary },
}));

//======================================
const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasSpecialChar
  );
};

//==============================================

const Signup = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [cmpName, setCmpName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const valid = validatePassword(password);
    setIsValid(valid);

    if (!valid) {
      // Password is not valid, do not proceed with registration
      return;
    }
    if (!userEmail || !password || !cmpName || !firstname || !lastname) {
      setMessage("All fields are required.");

      return;
    }
    if (!termsChecked) {
      setError("You must agree to the terms and conditions");
      return;
    } else {
      alert("Sign up successful");
    }

    setLoading(true);
    setMessage("");

    const getCurrentTimestamp = () => {
      const now = new Date();
      return now.toISOString().slice(0, 19).replace("T", " ");
    };

    const created_At = getCurrentTimestamp();
    const admin_From = getCurrentTimestamp();
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/auth/registration",
        {
          password,
          userEmail,
          firstname,
          lastname,
          cmpName,
          created_At,
          admin_From,
        }
      );

      if (response.data.exists) {
        setMessage(response.data.message);
      } else {
        setMessage(response.data.message);
        setFirstname("");
        setLastname("");
        setCmpName("");
        setUserEmail("");
        setPassword("");
        navigate("/");
      }
    } catch (error) {
      setMessage(
        `Error during registration: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  //==================== terms and conditons ==============================================

  const [termsChecked, setTermsChecked] = useState(false);
  const [error, setError] = useState("");

  const handleCheckboxChange = (event) => {
    setTermsChecked(event.target.checked);
    setError("");
  };

  //==================================================================

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/loginbackground.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "#ebfafc", //"#f2f2fc"//"rgba(245, 245, 245, 0.9)",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" }}
        >
          <img
            alt="logo"
            width="200px"
            height="70px"
            src="../../assets/Shraddha Infinite Logo.png" //"../../assets/shraddha logo.jpg"
          />
        </Typography>

        <Divider style={{ margin: "16px 0" }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              label="First Name"
              variant="outlined"
              fullWidth
              required
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SignupTextField
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Company"
              variant="outlined"
              fullWidth
              required
              value={cmpName}
              onChange={(e) => setCmpName(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <SignupTextField
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={isValid === false}
              helperText={
                isValid === false
                  ? "Password at least 8 characters, including uppercase, lowercase, and special characters."
                  : ""
              }
              disabled={loading}
              sx={{
                // Using the sx prop for inline styles
                "& .MuiInputBase-root": {
                  color: isValid === null ? "black" : isValid ? "green" : "red", // Text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      isValid === null ? "black" : isValid ? "green" : "red", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor:
                      isValid === null ? "#357AE8" : isValid ? "green" : "red", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor:
                      isValid === null ? "#357AE8" : isValid ? "green" : "red", // Focused border color
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "0" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label="Terms and Conditions"
            />
          </Grid>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}

        {/* ======================================== */}
        {message && (
          <Typography color="error" align="center" sx={{ marginTop: 2 }}>
            {message}
          </Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#4285F4",
            marginTop: "20px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357AE8",
            },
          }}
          onClick={handleRegister}
          disabled={loading}
        >
          {loading ? "Registering..." : "Register"}
        </Button>

        <Divider style={{ margin: "16px 0" }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "1px" }}
        >
          <Typography variant="body2" sx={{ color: "#000", fontSize: "13px" }}>
            Already have an account?{" "}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                variant="body2"
                sx={{ color: "blue", fontSize: "13px", display: "inline" }}
              >
                Log in
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Signup;
