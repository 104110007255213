
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  useTheme,
  Container,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ModuleDetailPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  const [moduleDetail, setModuleDetails] = useState(null);
  
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL+"/market/module/Detail",
          {
            module_id: id,
          },
          {
            headers: { Authorization: token },
          }
        );
        
        const modules = Object.values(response.data); // Assuming response.data is an object
        setModuleDetails(modules[0]); // Assuming you want to set the first module's details
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [id, token]); // Adding 'id' to the dependency array to refetch when it changes

  if (!moduleDetail) {
    return <Typography>Loading...</Typography>; // Loading state
  }

  return (
    <Container maxWidth="md" style={{ marginTop: "20px" }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia
              component="img"
              alt={moduleDetail.module_Name}
              height="400"
              image={moduleDetail.imageUrl || "https://via.placeholder.com/400"} // Ensure you have an image URL in your data
              title={moduleDetail.module_Name}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h4" component="h1" gutterBottom>
                {moduleDetail.module_Name}
              </Typography>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                {moduleDetail.module_Price} {/* Accessing the price from fetched data */}
              </Typography>
              <Typography variant="body1" paragraph>
                {moduleDetail.Module_Desc} {/* Accessing the description from fetched data */}
              </Typography>
              <Button variant="contained" color="primary">
                Buy Now
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
