import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";


export default function AdminReport() {
  const [AdminVchs, setVouchers] = useState([]);
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");


  useEffect(() => {
    fetchData();
  }, [token, cmpid]);


  //function to fetch all approval Voucher Details 
  const fetchData = async () => {
    try {
      const response = await axios.post( process.env.REACT_APP_API_URL+"/approvalModule/adminReport", { cmpid },
        {
          headers: { Authorization: token },
        }
      );
      const AdminArrayData = Object.values(response.data);
      setVouchers(AdminArrayData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


//Row for table
  const AdminStatusRow = Array.isArray(AdminVchs)
    ? AdminVchs.map((AdminVch) => ({
      id: AdminVch.vch_id, 
      vchDate: AdminVch.vch_date,
      partyName: AdminVch.PartyName,
      vchNumber: AdminVch.vch_number,
      vchTypes: AdminVch.vch_type,
      vchAmount: AdminVch.vch_Amount,
      isPrimary: AdminVch.isPrimary,
      Primary_Approver: AdminVch.Primary_Approver,
      isSecondary: AdminVch.isSecondary,
      Secondary_Approver: AdminVch.Secondary_Approver,
      isOptional: AdminVch.isOptional,
      Final_Approver: AdminVch.Final_Approver,
    }))
    : [];

  //columns for tables
  const AdminColumns = [
    {
      field: "Sr No", headerName: "Sr No", flex: 1,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    { field: "vchDate", headerName: "vch_date", flex: 1 },
    { field: "partyName", headerName: "PartyName Number", flex: 1 },
    { field: "vchNumber", headerName: "vch_number", flex: 1 },
    { field: "vchTypes", headerName: "vch_Type", flex: 1 },
    { field: "vchAmount", headerName: "vch_Amount", flex: 1 },
    { field: "isPrimary", headerName: "isPrimary", flex: 1 },
    { field: "Primary_Approver", headerName: "Primary_Approver", flex: 1 },
    { field: "isSecondary", headerName: "isSecondary", flex: 1 },
    { field: "Secondary_Approver", headerName: "Secondary_Approver", flex: 1 },
    { field: "isOptional", headerName: "isOptional", flex: 1 },
    { field: "Final_Approver", headerName: "Final_Approver", flex: 1 },
  ];

  //table of all vouchers 
  return (
    <Box m="20px">
      <Header title="Voucher Approval" subtitle=" Voucher Approval List " />
      <Box
        //m="40px 0 0 0"
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[150],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          },
        }}
      >
        <DataGrid
          rows={AdminStatusRow}
          columns={AdminColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </Box>
    </Box>
  );
}
