import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider } from "@mui/material";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)({
  "& .MuiInputLabel-root": { color: "#000" },
  "& .MuiInputLabel-root.Mui-focused": { color: "#000" },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#357AE8",
    },
  },
  "& .MuiOutlinedInput-input": { color: "#000" },
});

const Login = () => {
  const [userEmail, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => { 
  
    
    if (!userEmail || !password) {
      setMessage("Username and password are required.");
      return;
    }
    setLoading(true);
    setMessage("");
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+"/auth/login", {
        userEmail,
        password,
      });
      localStorage.setItem("token", response.data.token);
      navigate("/Dashboard");
      
    } catch (error) {
      setMessage(
        `${error.response?.data?.message || error.message}`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundImage: "url('../../assets/loginbackground.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: 2,
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "380px",
          padding: "40px",
          borderRadius: "10px",
          backgroundColor: "#ebfafc" ,//"rgba(245, 245, 245, 0.7)",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{ color: "#000", marginBottom: "30px" }}
        >
          <img
            alt="logo"
            width="200px"
            height="70px"
            src="../../assets/Shraddha Infinite Logo.png"      

          />
        </Typography>

        <Divider style={{ margin: '16px 0' }} />

        <SignupTextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          required
          value={userEmail}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
        />

        <SignupTextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          margin="normal"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        {message && <Typography color="error">{message}</Typography>}
        <Button
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#4285F4",
            marginTop: "20px",
            marginBottom: "20px",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357AE8",
            },
          }}
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? "Signing in..." : "Sign in"}
        </Button>


        <Divider style={{ margin: '16px 0' }} />

        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ marginTop: "1px" }}
        >
          <Link to="/forgot-password" style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#000",  fontSize: "13px" }}>
              Forgot Password?
            </Typography>
          </Link>
          <Link to="/signup" style={{ textDecoration: "none" }}>
            <Typography variant="body2" sx={{ color: "#4285F4", fontSize: "15px" }}>
              Register
            </Typography>
          </Link>
        </Box>


      </Paper>
    </Box>
  );
};

export default Login;
