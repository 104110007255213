import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Header from "../../components/Header";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";

//Global states and Varibales
export default function ApprovalModuleUsers() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [Users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [userId, setAppUsers] = useState();
  const [permissionLevel, setLevel] = useState();
  const [cmpid, setApproCmpname] = useState();
  const [UserList, setUserList] = useState([]);

  //handle to display add user Permission Popups
  const handleClickOpen = (row) => {
    setOpen(true);
  };
  // //handle to close add user Permission Popups
  const handleClose = () => {
    setOpen(false);
  };

  //=================================== select dropdown for permissiom Api ========================================
  const [CmpNameList, setCmpNameList] = useState([]);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setLevel(selectedValue);
  };

  const handleApproveCmpNameChange = (event) => {
    setApproCmpname(event.target.value);
  };

  //

  useEffect(() => {
    fetchuserData();
    fetchData();
    fetchDatalist();
  }, [navigate, token]);

  //function To fetch Company List
  const fetchData = async () => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL+"/company/list",
      {},
      {
        headers: { Authorization: token },
      }
    );
    const UserListData = Object.values(response.data.users);
    setCmpNameList(UserListData);
  };

  //Function For Create User Permissions
  const setPermission = async () => {
    try {
      const respone = await axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/user/create",
        {
          userId,
          permissionLevel,
          cmpid,
        },
        { headers: { Authorization: token } }
      );
      console.log("something", respone);
      setAppUsers("");
      setLevel("");
      setApproCmpname("");
      alert("Persmission added");
      handleClose();
      fetchDatalist();
    } catch (error) {
      console.log(error);
    }
  };

  //funstion fetch user List of login admin
  const fetchuserData = async () => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL+"/userAPI/list",
      {},
      {
        headers: { Authorization: token },
      }
    );

    const UserListData = Object.values(response.data.users);

    setUserList(UserListData);
  };

  //function to fecth User Permission list
  const fetchDatalist = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/user/list",
        {},

        {
          headers: { Authorization: token },
        }
      );

      const UserListData = Object.values(response.data);

      setUsers(UserListData);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  //handler for delete user Permision
  const handleperUserDelete = async (userId, permissionLevel, cmpid) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+`/approvalModule/user/delete/`,
        {
          userId,
          permissionLevel,
          cmpid,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response.status === 201) {
        fetchDatalist();
        alert("User permission deleted successfully.");
      }
    } catch (error) {
      alert("Something went Wrong .");
    }
  };

  //rows for User Permission Table
  const userListRow = Users.map((UserList) => ({
    id: UserList.sr,
    user_Name: UserList.user_Name,
    permission_Level: UserList.permission_Level,
    cmp_id: UserList.cmp_id,
    userId: UserList.user_id,
  }));

  //column for user permision table
  const columns = [
    {
      field: "id",
      headerName: "id",
      flex: 1,
    },
    {
      field: "user_Name",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "permission_Level",
      headerName: "Permission Level",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "cmp_id",
      headerName: "cmp_id",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              style={{ backgroundColor: colors.blueAccent[700] }}
              size="small"
              type="button"
              onClick={() =>
                handleperUserDelete(
                  params.row.userId,
                  params.row.permission_Level,
                  params.row.cmp_id
                )
              }
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* ===================== add  Permission Allocations popup window =========================== */}
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "350px", // Set custom width
              maxWidth: "80%", // Set max width for responsiveness
              height: "450px", // Set custom height
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "24px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // This ensures space between items
              marginTop: "15px",
              padding: "0 16px", // Optional: Add some horizontal padding for better spacing
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <PersonAddAltIcon
                sx={{ marginRight: "8px", height: "50px", width: "30px" }}
              />
              Permission Allocations
            </div>
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle" sx={{ fontSize: "18px" }}>
              Please fill out the form below:
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <InputLabel id="User-Name-label">User List</InputLabel>
              <Select
                labelId="User-Name-label"
                id="User-Name-label"
                label="User List"
                value={userId}
                onChange={(e) => setAppUsers(e.target.value)}
              >
                <MenuItem value="">
                  <em>User List</em>
                </MenuItem>
                {UserList.map((user) => (
                  <MenuItem key={user.user_Id} value={user.user_Id}>
                    {user.user_Name}
                  </MenuItem>
                ))}
              </Select>

              <InputLabel id="permission-level-label">
                Permission Level
              </InputLabel>
              <Select
                labelId="permission-level-label"
                id="permission-level-label"
                label="Permission Level"
                value={permissionLevel}
                onChange={handleChange}
              >
                <MenuItem>
                  <em>Permission Level</em>
                </MenuItem>
                <MenuItem value={"Primary"}>Primary Voucher Approval </MenuItem>
                <MenuItem value={"Secondary"}>
                  Secondary Voucher Approval
                </MenuItem>
                <MenuItem value={"Final"}>Final Voucher Approval</MenuItem>
              </Select>
              <InputLabel id="Tally-Compnay-label">
                Select Tally Company
              </InputLabel>
              <Select
                labelId="Tally-Compnay-label"
                id="Tally-Compnay-label"
                label="Select Tally Company name "
                lue={cmpid}
                onChange={handleApproveCmpNameChange}
              >
                <MenuItem>
                  <em>Tally company List</em>
                </MenuItem>
                {CmpNameList.map((cmpname) => (
                  <MenuItem key={cmpname.Cmp_Id} value={cmpname.Cmp_Id}>
                    {cmpname.Cmp_Name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center", // Center the buttons
              alignItems: "center", // Align items vertically center
              mb: 2, // Optional: add some margin below the buttons
              //  mt:1
            }}
          >
            <Button
              onClick={handleClose}
              size="small"
              color="error"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              color="success"
              size="small"
              variant="contained"
              onClick={setPermission}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {/* ================= show user list ================================== */}

      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Header positioned on the left */}
          <Header title="Approval Module User" subtitle="All User List" />

          {/* Button positioned on the right */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[700],
            }}
            startIcon={<PersonAddAltRoundedIcon />}
            size="small"
            onClick={handleClickOpen}
          >
            Add User Permission
          </Button>
        </Box>

        <Box
          //  m="40px 0 0 0"
          height="74vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              color: colors.primary[150],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid rows={userListRow} columns={columns} />
        </Box>
      </Box>
    </>
  );
}
