import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EditIcon from "@mui/icons-material/Edit";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../../components/Header";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

//======================password validation ================
const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  // Check if the password meets the criteria or is the default "*"
  if (
    password === "*" || // Allow default password
    (password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasSpecialChar)
  ) {
    return true; // Valid password
  } else {
    return false; // Invalid password
  }
};

//=======================================================

const Team = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [Users, setUsers] = useState([]);
  const token = localStorage.getItem("token");
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false); // State to manage dialog visibility

  //============== add new user popup code ===================================

  const handleClickOpen = () => {
    setOpen(true); // Open the dialog
  };
  const handleClose = () => {
    setOpen(false); // Close the dialog
    setShowPassword(true);
    setShowConPassword(true);
  };

  // =================== show and hide password code ==================

  const [showPassword, setShowPassword] = useState(true); // State to manage Passoword visibility
  const [showConPassword, setShowConPassword] = useState(true); // State to manage confirm Passoword visibility

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleShowConfPassword = () => {
    setShowConPassword((prev) => !prev);
  };

  //===============================# Add new user api code #================================

  const [userRole, setuserRole] = useState("");
  const [userEmail, setuserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cmpassword, setcmPassword] = useState("");
  const [userName, setuserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(null);

  const AddNewUser = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    const valid = validatePassword(password);
    setIsValid(valid);

    if (!valid) {
      // Password is not valid, do not proceed with registration
      return;
    }

    if (!userName || !userEmail || !password || !userRole) {
      alert("All fields are required.");
      return;
    }

    if (password !== cmpassword) {
      alert("Passwords do not match.");
      return;
    }

    // Get current timestamp
    const getCurrentTimestamp = () => {
      return new Date().toISOString().slice(0, 19).replace("T", " ");
    };
    const created_At = getCurrentTimestamp();

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/userAPI/create",
        {
          userName,
          userEmail,
          password,
          created_At,
          userRole,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response.data.exists) {
        alert(response.data.message);
      } else {
        alert(response.data.message);

        setuserEmail("");
        setuserName("");
        setPassword("");
        setuserRole("");
        navigate("/team");
      }
    } catch (error) {
      alert(
        `Error during registration: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  //======================== user list Api code =================================================

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/userAPI/list",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const UserListData = Object.values(response.data.users);
      setUsers(UserListData);
    } catch (error) {
      alert("Something went wrong ");
    }
  };
  //======================================================

  useEffect(() => {
    fetchData();
  });

  //================================================================

  const rolesMap = {
    1: "Owner",
    2: "User",
  };

  const isActiveMap = {
    1: "Active",
    0: "InActive",
  };
  //====================== user list Row ===================================

  const userListRow = Users.map((user) => ({
    id: user.user_Id, // Use user_Id, fallback to index if necessary
    userName: user.user_Name,
    Email: user.user_Email,
    isActive: user.user_active,
    Role: user.user_Role,
  }));

  //========================= user list columns ========================
  const columns = [
    { field: "id", headerName: "User ID", flex: 1 },

    {
      field: "userName",
      headerName: "Name",
      // type: "text",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "IsActive",
      flex: 1,
      renderCell: (params) => {
        return isActiveMap[params.value] || "InActive";
      },
    },
    {
      field: "Role",
      headerName: "Role",
      width: 1,
      renderCell: (params) => {
        return rolesMap[params.value] || "Unknown";
      },
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            sx={{ backgroundColor: colors.blueAccent[700] }}
            startIcon={<EditIcon />}
            size="small"
            type="button"
            onClick={() => handleEditUpdate(params.row)}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      field: "Select Company",
      headerName: "Select Company",
      headerAlign: "center",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        return (
          <div>
            <Button
              variant="contained"
              sx={{ backgroundColor: colors.blueAccent[700] }}
              type="button"
              size="small"
              onClick={() => handleCmpPopUpOpen(params.row.id)}
            >
              Add Company
            </Button>
          </div>
        );
      },
    },
  ];

  //================  select company popUp widows code =========================================

  const [Selectopen, setSelectopen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCmpPopUpOpen = (id) => {
    setSelectopen(true);
    console.log("user id", id);
  };

  const handleCmpPopUpClose = () => {
    setSelectopen(false);
  };

  const handleToggleOption = (option) => {
    const currentIndex = selectedOptions.indexOf(option.Cmp_Name);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(option.Cmp_Name);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }
    setSelectedOptions(newSelectedOptions);
  };

  const handleSubmit = () => {
    alert(`Selected options: ${selectedOptions.join(", ")}`);
    handleCmpPopUpClose();
  };

  //===================== company name list code ===========================

  const [CmpNameList, setCmpNameList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "/company/list",
          {},
          {
            headers: { Authorization: token },
          }
        );

        const UserListData = Object.values(response.data.users);
        setCmpNameList(UserListData);
      } catch (error) {
        alert("Something went Wrong");
      }
    };

    fetchData();
  }, [navigate, token]);

  //======================= user Edit Code ================================================

  const [openEdit, setEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [name, setName] = useState("");
  const [UpPassword, setUpPassword] = useState("*");
  const [CmPassword, setCmPassword] = useState("*");
  const [Role, setRole] = useState("");
  const [isActive, setIsActive] = useState("");
  const [isEditValid, setIsEditValid] = useState(true);

  const handleEditUpdate = (user) => {
    setSelectedUser(user);
    setName(user.userName);
    setIsActive(user.isActive === 1 ? "Active" : "InActive");
    setRole(user.Role === 1 ? "Owner" : "User");
    setEditOpen(true);
    setUpPassword(user.target.value);
    setCmPassword(user.target.value);
  };

  const handleEditClose = () => {
    setEditOpen(false); // Close the popover
    setSelectedUser(null);
  };

  //===============================================
  const currentDate = new Date();
  const formattedDate = currentDate
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  //==================== user Delete Code ==============================
  const handleEditDelete = () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this user?"
    );

    if (confirmation) {
      deleteUser(selectedUser.id);
    }
  };

  const deleteUser = async (id) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL + `/userAPI/delete/`,
        {
          userId: id,
        },
        {
          headers: { Authorization: token },
        }
      );
      fetchData();
      alert("User deleted successfully");
      handleEditClose();
    } catch (error) {
      alert("An error occurred while deleting the user");
    }
  };

  //================ User Updated Code ============================

  const handleEditSubmit = async (e) => {
    // Input validation
    e.preventDefault();

    const valid = validatePassword(UpPassword);

    setIsEditValid(valid);

    if (!valid) {
      return;
    }

    //=================================================================

    if (!name || !UpPassword || !CmPassword) {
      alert("Please fill in all fields.");
      return; // Early exit if validation fails
    }
    if (UpPassword !== CmPassword) {
      alert("Passwords do not match...");
      return;
    }
    try {
      // Create the updated user object
      const updatedUser = {
        userId: selectedUser.id, // Assuming you have this field
        userName: name,
        password: UpPassword,
        isActive: isActive === "Active" ? 1 : 0,
        userRole: Role === "Owner" ? 1 : 2,
        updated_at: formattedDate, //"2024-10-24 07:52:02",
      };

      await axios.post(
        process.env.REACT_APP_API_URL + `/userAPI/update`,
        updatedUser,
        {
          headers: { Authorization: token },
        }
      );
      fetchData();
      alert("User updated successfully.");

      handleEditClose();
    } catch (error) {
      alert("An error occurred while updating the user. Please try again.");
    }
  };

  //=============================================================================
  return (
    <>
      {/* ======================= open popup for user Edit ================= */}
      <Box sx={{ p: 2 }}>
        <Dialog
          open={openEdit}
          onClose={handleEditClose}
          PaperProps={{
            sx: {
              display: "flex",
              width: "300px", // Set your desired width
              height: "480px", // Conditional height based on updated state
              maxHeight: "100%", // Ensure it doesn't exceed the viewport height
              maxWidth: "100%", // Ensure it doesn't exceed the viewport width
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <DialogTitle>
            <Typography>Update User Information</Typography>

            <CancelIcon
              sx={{
                position: "absolute", // Position the icon absolutely
                top: "10px", // Adjust as needed
                right: "10px", // Adjust as needed
                height: "30px",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={handleEditClose}
            />
          </DialogTitle>

          <DialogContent>
            <form>
              <TextField
                size="small"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <InputLabel>Role</InputLabel>
              <Select
                size="small"
                value={Role}
                onChange={(e) => setRole(e.target.value)}
                fullWidth
                margin="normal"
                required
              >
                <MenuItem value="" disabled>
                  Select User Role
                </MenuItem>
                <MenuItem value="Owner">Owner</MenuItem>
                <MenuItem value="User">User</MenuItem>
              </Select>

              <InputLabel>Is Active</InputLabel>
              <Select
                size="small"
                value={isActive}
                onChange={(e) => setIsActive(e.target.value)}
                fullWidth
                margin="normal"
                required
              >
                <MenuItem value="" disabled>
                  Select User Is Active
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="InActive">In Active</MenuItem>
              </Select>

              <TextField
                size="small"
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={UpPassword}
                onChange={(e) => setUpPassword(e.target.value)}
                type="password"
                required
                error={isEditValid === false}
                helperText={
                  isEditValid === false
                    ? "Password must be at least 8 characters, including uppercase, lowercase, and special characters."
                    : ""
                }
                sx={{
                  // Using the sx prop for inline styles
                  "& .MuiInputBase-root": {
                    color:
                      isEditValid === null
                        ? "black"
                        : isEditValid
                        ? "green"
                        : "red", // Text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "black"
                          : isEditValid
                          ? "green"
                          : "red", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "#357AE8"
                          : isEditValid
                          ? "green"
                          : "red", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        isEditValid === null
                          ? "#357AE8"
                          : isEditValid
                          ? "green"
                          : "red", // Focused border color
                    },
                  },
                }}
              />
              <TextField
                size="small"
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={CmPassword}
                onChange={(e) => setCmPassword(e.target.value)}
                type="password"
                required
                error={CmPassword && UpPassword !== CmPassword}
                helperText={
                  CmPassword && UpPassword !== CmPassword
                    ? "Passwords do not match."
                    : ""
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  gap: 1,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={handleEditSubmit}
                >
                  Update
                </Button>
                <Button
                  size="small"
                  type="submit"
                  variant="contained"
                  color="error"
                  onClick={handleEditDelete}
                >
                  Delete
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Box>

      {/* ================== open popup for select company  ====================== */}
      <Dialog
        open={Selectopen}
        onClose={handleCmpPopUpClose}
        PaperProps={{
          sx: {
            width: "400px", // Set your desired width
            height: "400px", // Set your desired height
            maxHeight: "90%", // Ensure it doesn't exceed the viewport height
            maxWidth: "90%", // Ensure it doesn't exceed the viewport width
            overflowY: "auto", // Allow scrolling if content overflows
          },
        }}
      >
        <DialogTitle variant="h3">Select Company</DialogTitle>
        <DialogContent>
          <Typography variant="h4" align="center">
            Choose your options:
          </Typography>
          <FormGroup
            sx={{
              size: "small",
              display: "flex",
              flexDirection: "column", // Stack options vertically
              alignItems: "center", // Align items to the start
              justifyContent: "center", // Start from top vertically
              marginLeft: "110px",
            }}
          >
            {CmpNameList.map((option) => (
              <FormControlLabel
                key={option.Cmp_Id}
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option.Cmp_Name)}
                    onChange={() => handleToggleOption(option)}
                  />
                }
                label={option.Cmp_Name}
                sx={{
                  display: "flex",
                  flexDirection: "row", // Set the flex direction to row for checkbox and label
                  alignItems: "center", // Center checkbox and label vertically
                  justifyContent: "space-between 20px", // Spread items evenly
                  width: "100%", // Ensure full width
                }}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", marginBottom: "20px", gap: 2 }}
        >
          <Button
            onClick={handleCmpPopUpClose}
            color="error"
            variant="contained"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit(selectedOptions)}
            color="success"
            variant="contained"
            size="small"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* ============================================================== */}
      {/* add new user popup window */}
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "350px", // Set custom width
              maxWidth: "80%", // Set max width for responsiveness
              height: "485px", // Set custom height
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "24px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // This ensures space between items
              marginTop: "15px",

              padding: "0 16px", // Optional: Add some horizontal padding for better spacing
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <PersonAddAltIcon
                sx={{ marginRight: "8px", height: "50px", width: "30px" }}
              />
              Add New User
            </div>
            <CancelIcon
              sx={{
                height: "50px",
                width: "30px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle">
              Please fill out the form below:
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <TextField
                size="small"
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                name="name"
                fullWidth
                variant="outlined"
                value={userName}
                onChange={(e) => setuserName(e.target.value)}
                disabled={loading}
                required
              />
              <TextField
                size="small"
                margin="dense"
                label="Email"
                type="email"
                name="email"
                fullWidth
                variant="outlined"
                value={userEmail}
                onChange={(e) => setuserEmail(e.target.value)}
                disabled={loading}
                required
              />

              <InputLabel>Role</InputLabel>
              <Select
                size="small"
                value={userRole}
                onChange={(e) => setuserRole(e.target.value)}
                disabled={loading}
                required
              >
                <MenuItem value="" disabled>
                  Select a Role
                </MenuItem>
                <MenuItem value={1}>Owner</MenuItem>
                <MenuItem value={2}>User</MenuItem>
              </Select>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Password"
                type={showPassword ? "password" : "text"}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={isValid === false}
                helperText={
                  isValid === false
                    ? "Password at least 8 characters, including uppercase, lowercase, and special characters."
                    : ""
                }
                disabled={loading}
                sx={{
                  // Using the sx prop for inline styles
                  "& .MuiInputBase-root": {
                    color:
                      isValid === null ? "black" : isValid ? "green" : "red", // Text color
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        isValid === null ? "black" : isValid ? "green" : "red", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor:
                        isValid === null
                          ? "#357AE8"
                          : isValid
                          ? "green"
                          : "red", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor:
                        isValid === null
                          ? "#357AE8"
                          : isValid
                          ? "green"
                          : "red", // Focused border color
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                margin="dense"
                label="Confirm Password"
                type={showConPassword ? "password" : "text"}
                name="Password"
                fullWidth
                variant="outlined"
                value={cmpassword}
                onChange={(e) => setcmPassword(e.target.value)}
                disabled={loading}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowConfPassword}
                        edge="end"
                      >
                        {showConPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={cmpassword && password !== cmpassword}
                helperText={
                  cmpassword && password !== cmpassword
                    ? "Passwords do not match."
                    : ""
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%", // Ensure the Box takes 80% width
                mr: 3.5,
                mb: 2,
              }}
            >
              <Button
                type="submit"
                onClick={AddNewUser}
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  borderRadius: "10px", // Add border radius
                  //transition: "transform 0.3s ease", // Smooth transition for hover effect
                  "&:hover": {
                    // transform: "scale(1.15)", // Zoom effect on hover
                    backgroundColor: colors.blueAccent[700],
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>

      {/* ================= show user list ================================== */}
      <Box m="20px" mt="0">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Header positioned on the left */}
          <Header title="User" subtitle="All User List" />

          {/* Button positioned on the right */}
          <Button
            variant="contained"
            style={{ backgroundColor: colors.blueAccent[700] }}
            startIcon={<PersonAddAltRoundedIcon />}
            size="small"
            onClick={handleClickOpen}
          >
            Add User
          </Button>
        </Box>
        <Box
          // m="40px 0 0 0"
          height="74vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              color: colors.primary[150],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid rows={userListRow} columns={columns} />
        </Box>
      </Box>
    </>
  );
};
export default Team;
