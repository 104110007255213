import {
    Card,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Container,
    Grid,
    useTheme,
    Snackbar,
  } from "@mui/material";
  import { tokens } from "../../theme";
  import { useNavigate } from "react-router-dom";
  import { AddShoppingCart } from "@mui/icons-material";
  import React, { useEffect, useState } from "react";
  import axios from "axios";
  
  // Define the ProductCard component
  const ModuleCard = ({ module }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const handleBuyNowClick = (id) => {
     // navigate(`/ModuleDetailPage`); 
     navigate(`/ModuleDetailPage/${id}`);
      console.log('fgdfgfdg id',id)
    };
  
    //=============================================================

    return (
      <Card sx={{ maxWidth: 345, margin: 2 }}>
        <CardMedia
          component="img"
          alt={module.module_Name}
          height="140"
          image="https://via.placeholder.com/150" // Placeholder image; update with module image if available
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {module.module_Name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {module.Module_Desc}
          </Typography>
          <Typography variant="h6" color="primary">
            ${module.module_Price.toFixed(2)}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: colors.blueAccent[700],
            }}
            startIcon={<AddShoppingCart />}
            fullWidth
            onClick={() => handleBuyNowClick(module.module_Id)}
          >
          Learn More
          </Button>
        </CardContent>
      </Card>
    );
  };
  
  //======================================= Main MarketPlace component

  export default function MarketPlace() {
    const navigate = useNavigate();
    const theme = useTheme();
    const token = localStorage.getItem("token");
    const colors = tokens(theme.palette.mode);
  
    const [moduleList, setModuleList] = useState([]);
    const [error, setError] = useState(null); // State to manage errors
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            process.env.REACT_APP_API_URL+"/market/module/list",
            {},
            {
              headers: { Authorization: token },
            }
          );
          const modules = Object.values(response.data); // Assuming the response data is an object
          setModuleList(modules);
          console.log(modules);
        } catch (error) {
          console.error("Error fetching data", error);
          setError("Error fetching module data. Please try again later."); // Set error message
        }
      };
  
      fetchData();
    }, [navigate, token]);
  
    return (
      <>
        <Container maxWidth="lg" sx={{ marginTop: 5 }}>
          <Typography variant="h4" gutterBottom>
            Buy Now Module
          </Typography>
          <Grid container spacing={3}>
            {moduleList.map((module) => (
              <Grid item xs={12} sm={6} md={3} key={module.id}> {/* Ensure module has an id */}
                <ModuleCard module={module} />
              </Grid>
            ))}
          </Grid>
        </Container>
  
        {/* Error Snackbar */}
        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            message={error}
          />
        )}
      </>
    );
  }
  