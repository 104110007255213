import React, { useState } from 'react';
import { Box, Paper, Typography, TextField, Button, Divider } from "@mui/material";
import { styled } from "@mui/material";
import axios from "axios";

// Custom styled TextField component to match the style
const SignupTextField = styled(TextField)({
    "& .MuiInputLabel-root": { color: "#000" },
    "& .MuiInputLabel-root.Mui-focused": { color: "#000" },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#000",
        },
        "&:hover fieldset": {
            borderColor: "#000",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#357AE8",
        },
    },
    "& .MuiOutlinedInput-input": { color: "#000" },
});

const ResetPwd = () => {
    const [cnfpass, setcnfpass] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
 

    const handleresetPwd = async () => { 

        console.log("password : ",cnfpass)
        if (password !== cnfpass) {
            setMessage("Password Do Not macth");
            return;
        }
        setLoading(true); 

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL+"/auth/reset-password", {
                token,
                password,
            });
            setMessage(response.data.message)

        } catch (error) {
            setMessage(
                `${error.response?.data?.message || error.message}`
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
                padding: 2,
            }}
        >
            <Paper
                elevation={1}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "380px",
                    padding: "40px",
                    borderRadius: "10px",
                    backgroundColor: "rgba(245, 245, 245, 0.7)",
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    sx={{ color: "#000", marginBottom: "30px" }}
                >
                    <img
                        alt="logo"
                        width="200px"
                        height="70px"
                        src="../../assets/shraddha logo.jpg"

                    />
                </Typography>

                <Typography variant="body2" sx={{ fontSize: "18px" }}>
                    Change Password
                </Typography>

                <Divider style={{ margin: '16px 0' }} />
                <SignupTextField
                    label="New Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={loading}
                />

                <SignupTextField
                    label="Confirm Password"
                    variant="outlined"
                     type="password"
                    fullWidth
                    margin="normal"
                    required
                    value={cnfpass}
                    onChange={(e) => setcnfpass(e.target.value)}
                    disabled={loading}
                />

                {message && <Typography color="sucess">{message}</Typography>}
                <Button
                    variant="contained"
                    fullWidth
                    sx={{
                        backgroundColor: "#4285F4",
                        marginTop: "20px",
                        marginBottom: "20px",
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#357AE8",
                        },
                    }}
                    onClick={handleresetPwd}
                    disabled={loading}
                >
                    Submit
                </Button>

            </Paper>
        </Box>
    );
};

export default ResetPwd;
