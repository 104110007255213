import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import ProgressCircle from "../../components/ProgressCircle";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from "@mui/material";

const Dashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const cmpname = localStorage.getItem("cmpname");
  const [open, setOpen] = useState();
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setOpen(false); 
    window.location.reload();
  };
  const [CmpNameList, setCmpNameList] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(localStorage.getItem("cmpid"));
  const [selectedCompanyName, setSelectedCompanyName] = useState(localStorage.getItem("cmpname"));
  console.log('cmp name ', selectedCompanyName);
  console.log('cmp id ', selectedCompanyId);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedCompany = CmpNameList.find( (cmp) => cmp.Cmp_Id === selectedId
    ); 
 
     localStorage.setItem("cmpid", selectedId);
     localStorage.setItem("cmpname",  selectedCompany.Cmp_Name); 

  };

  //============================== select tally comapny code ==========================
  useEffect(() => {
    if (cmpname === "" || cmpname === null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [cmpname]);

  //======================== cmp name  api code ===================================

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        navigate("/login");
        return;
      }

      //   try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/company/list",
        {},
        {
          headers: { Authorization: token },
        }
      );

      const UserListData = Object.values(response.data.users);
      setCmpNameList(UserListData);
      // console.log("response : ",UserListData)

      //   } catch (error) {
      //     console.error("Error fetching data", error);
      //     navigate("/login");
      //   }
    };

    fetchData();
  }, [navigate, token]);

  return (

    // code for company Selection popups
    <>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "500px",
              maxWidth: "50%", 
            },
          }}
        >
          <DialogTitle
            sx={{
              fontSize: "24px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", 
              marginTop: "15px",

              padding: "0 16px", 
            }}
          >
            Select Tally Company
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <InputLabel id="Tally-Compnay-label">
                Select Tally Company
              </InputLabel>
              <Select
                labelId="Tally-Compnay-label"
                id="Tally-Compnay-label"
                label="Select Tally Company name "
                lue={selectedCompanyId}
                onChange={handleSelectChange}
              >
                <MenuItem>
                  <em>Tally company List</em>
                </MenuItem>
                {CmpNameList.map((cmpname) => (
                  <MenuItem key={cmpname.Cmp_Id}  value={cmpname.Cmp_Id}>{cmpname.Cmp_Name}</MenuItem>
                ))}
              </Select>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="success" variant="contained">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Box> 


      {/* Code for dashboard */}

      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" >
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard!" />
        </Box>

        {/* Main Box */}
        <Box
          display="grid" 
          gridTemplateColumns="repeat(12,1fr)" 
          gridAutoRows="140px"
          gap="20px" 
        >
          <Box
            gridColumn="span 9"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Box
              mt="25px"
              p="0 30px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  variant="h5"
                  fontWeight="600"
                  color={colors.grey[100]}
                >
                  Revenue Generated
                </Typography>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  color={colors.greenAccent[500]}
                >
                  $59,342
                </Typography>
              </Box>

              <Box>
                <IconButton>
                  <DownloadOutlinedIcon
                    sx={{
                      fontSize: "26px",
                      color: colors.greenAccent[500],
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box height="250px" mt="-20px">
              <LineChart isDashboard={true} />
            </Box>
          </Box>

          {/* Campaign box */}
          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
            p="30px"
          >
            <Typography variant="h5" fontWeight="600">
              Campaign
            </Typography>
            <Box
              display="flex"
              flexDirection="column" 
              alignItems="center"
              mt="25px"
            >
              <ProgressCircle size="125" />
              <Typography
                variant="h5"
                color={colors.greenAccent[500]}
                sx={{ mt: "15px" }}
              >
                $48,352 revenue generated
              </Typography>
              <Typography>
                Includes Extra Misc Expenditures and Costs
              </Typography>
            </Box>
          </Box>

          {/* // */}

          <Box
            gridColumn="span 4"
            gridRow="span 2"
            backgroundColor={colors.primary[400]}
          >
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ p: "30px 30px 0 30px" }}
            >
              Sales Quantity
            </Typography>
            <Box height="250px" mt="-20px">
              <BarChart isDashboard={true} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
