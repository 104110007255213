import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

const FinalVchApprov = () => {

  const [FinalVchs, setVouchers] = useState([]);
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const [selectedIds, setSelectedIds] = useState([]);
  const [rejectId , setrejectId] =useState("");
  const [rejectReason , setrejectReason] =useState("");

  useEffect(() => {
    fetchData();
  }, [token, cmpid]);


  //function to fetch Vouchers Data 
  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/finalList", { cmpid },
        {
          headers: { Authorization: token },
        }
      );
      const FinalArrayData = Object.values(response.data);
      setVouchers(FinalArrayData);
    }
    catch (error) {
      console.error("Error fetching data", error);
    }
  };



  const hnadleReject = (id)=> {
    setrejectId(id)
    setOpen(true); 
  }
  

  //funnction to approve or reject single Voucher
  const handleApproval = (id, status) => { 

    const rejectionremark= rejectReason
    axios.post(`http://64.227.138.8/approvalModule/finalUpdate`, { id, status ,rejectionremark},
      {
        headers: { Authorization: token },
      }
    )
      .then((response) => {
        setVouchers(FinalVchs.filter((FinalVch) => FinalVch.vch_id !== id));
      })
      .catch((error) => {
        console.error("Error updating voucher status:", error);
      });

    if (status == 1) {
      alert("Approve SuccessFully");
      fetchData();
    } else {
      alert("Rejected ");
      setrejectReason("")
      handleClose()
    }
  };


  //handle bulk approve and reject
  const handleApprovalAll = (status) => {
    const selectedVoucherIds = FinalVchsRow
      .filter((FinalVchsRow) => selectedIds.includes(FinalVchsRow.id))
      .map((FinalVchsRow) => FinalVchsRow.id);
    const formattedArray = selectedVoucherIds.map(id => ({ id, status }));
    console.log("formattedArray : ", formattedArray)
    try {
      axios.post(process.env.REACT_APP_API_URL+"/approvalModule/allFinalUpdate", formattedArray,
        {
          headers: { Authorization: token },
        }
      );
      alert("Aprroved Suucess")
      fetchData();
    } catch (error) {
      alert("Something wents wrong")
      console.error("Error fetching data", error);

    }
  };



  //Rows for voucher tables
  const FinalVchsRow = Array.isArray(FinalVchs)
    ? FinalVchs.map((FinalVch) => ({
      id: FinalVch.vch_id, // Ensure this is unique
      vchDate: FinalVch.vch_date,
      partyName: FinalVch.PartyName,
      vchNumber: FinalVch.vch_number,
      vchTypes: FinalVch.vch_type,
      vchAmount: FinalVch.vch_Amount,
    }))
    : [];

//columns for vouchers table
  const FinalVchColumns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      flex: 1,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    { field: "vchNumber", headerName: "vch_number", flex: 1 },
    { field: "vchTypes", headerName: "vch_Type", flex: 1 },
    { field: "vchDate", headerName: "vch_date", flex: 1 },
    { field: "partyName", headerName: "PartyName", flex: 1 },
    { field: "vchAmount", headerName: "vch_Amount", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            color="success"
            size="small"
            startIcon={<VerifiedIcon />}
            type="button"
            onClick={() => handleApproval(params.id, "1")}
            style={{ marginRight: "10px" }}
          >Approve </Button>
          
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<CancelRoundedIcon />}
            type="button"
            id="Rejected"
            onClick={() => hnadleReject(params.id)}
          > Reject </Button>
        </div>
      ),
    },
  ];


 //add reject reason Popup Form  
  //========================================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState();

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return ( 


    <>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "400px",
              maxWidth: "50%",
              height: "215px",

            },
          }}
        >
          <DialogTitle variant="h3">Add Reason for Reject</DialogTitle>

          <DialogContent>
            <Box component="form">
              <TextField
                size="small"
                variant="outlined" 
                label="Enter Reason" 
                fullWidth 
                margin="normal"  
                value={rejectReason}
                onChange={(event) => setrejectReason(event.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => handleApproval(rejectId ,"0")}
              sx={{
                backgroundColor: colors.blueAccent[700],
                mb: 2,
                mr: 2,
               }}
              variant="contained"
              size="small" 
            
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* =================================================== */}
  
    
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Voucher Approval" subtitle="Final Voucher Approval" />
        {/* buttons for bulk Approve and reject */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button variant="contained" color="success" size="small" onClick={() => handleApprovalAll(1)}  >
            Approve All
          </Button>
          <Button variant="contained" color="error" size="small" onClick={() => handleApprovalAll(0)}>
            Reject All
          </Button>
        </Box>
      </Box>

      <Box
        // m="40px 0 0 0"
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            color: colors.primary[150],
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[150],
          }, 
          "& .MuiDataGrid-cell:focus":
           { outline: "none" },
        }}
      >
        <DataGrid
          rows={FinalVchsRow}
          columns={FinalVchColumns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          onSelectionModelChange={(newSelection) => setSelectedIds(newSelection)}
        />
      </Box>
    </Box>  
    </>
  );
};
export default FinalVchApprov;
