import { Box, Switch, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Header from "../../components/Header";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";

export default function TallyCompanies() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [CmpNames, setcmpName] = useState([]);
  const token = localStorage.getItem("token");
  // const Username = localStorage.getItem("userName");
  const colors = tokens(theme.palette.mode);

  //======================== user list Api code =================================================

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
      return;
    }

   try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL+"/company/list",
      {},
      {
        headers: { Authorization: token },
      }
    );

    const CmpListData = response.data.users;
    setcmpName(CmpListData);
  
      } catch (error) {
        console.error("Error fetching data", error);
        
      }
  };

  useEffect(() => {
  
    fetchData();
  }, [navigate, token]);

  //=======================================================
  const [selectedId, setSelectedId] = useState(false);
  const [selectedName, setSelectedName] = useState(false);
  //handle to select comapany 
  const handleSelect = (id, Cmp_Name) => {
    setSelectedId(selectedId === id ? null : id);
    setSelectedName(selectedName === Cmp_Name ? null : Cmp_Name);

    localStorage.setItem("cmpid", id);
    localStorage.setItem("cmpname", Cmp_Name);
    window.location.reload();
  };

  const handleAppMToggle = (id, newStatus) => {
  
    const updatedModuleRow = ModuleRow.map((row) =>
      row.id === id ? { ...row, approval_Module: newStatus } : row
    );
     
    const updatedRow = updatedModuleRow.find((row) => row.id === id);
    const UpdatedModuleData= {
      cmpId: updatedRow.id,
      approvalModule: updatedRow.approval_Module,
      creditManagement: updatedRow.credit_Management,
    };
 

    try {
      axios.post(
          process.env.REACT_APP_API_URL+"/company/enableModule",UpdatedModuleData ,
    
          {
            headers: { Authorization: token },
          } 
        ); 
        fetchData()
         } catch (error) {
           console.error("Error fetching data", error);
        
          } 

  };
  
  const handleCreditMToggle = (id, newStatus) => {

    const updatedModuleRow = ModuleRow.map((row) =>
      row.id === id ? { ...row, credit_Management: newStatus } : row
    );
  

    const updatedRow = updatedModuleRow.find((row) => row.id === id);
    const UpdatedModuleData= {
      cmpId: updatedRow.id,
      approvalModule: updatedRow.approval_Module,
      creditManagement: updatedRow.credit_Management,
    };
    

    try {
      axios.post(
          process.env.REACT_APP_API_URL+"/company/enableModule",UpdatedModuleData ,
    
          {
            headers: { Authorization: token },
          } 
        ); 
        fetchData()
         } catch (error) {
           console.error("Error fetching data", error);
        
          } 


  };

  const userListRow = CmpNames.map((users) => ({
    id: users.Cmp_Id,
    Cmp_Name: users.Cmp_Name,
    admin_Id: users.admin_Id,
  }));

  const ModuleRow = CmpNames.map((users) => ({
    id: users.Cmp_Id,
    Cmp_Name: users.Cmp_Name,
    approval_Module: users.approval_Module,
    credit_Management: users.credit_Management,
  }));
  


  //===================== This column for module list =============================
  
  const Modulecolumns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      flex: 1,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    {
      field: "Cmp_Name",
      headerName: "Company Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "approval_Module",
      headerName: "Approval Module",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const { id, approval_Module } = params.row;
        const isChecked = approval_Module === 1; 
  
        return (
          <Switch
            checked={isChecked}
            onChange={() => { 
              
              const newStatus = isChecked ? 0 : 1;  
            
              handleAppMToggle(id, newStatus); 
            }}
            color="success"
            inputProps={{ "aria-label": `controlled-${params.value}` }}
          />
        );
      },
    },
    {
      field: "credit_Management",
      headerName: "Credit Management",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const { id, credit_Management } = params.row;
        const isCMChecked = credit_Management === 1; 
  
        return (
          <Switch
            checked={isCMChecked}
            onChange={() => {
              const newStatus = isCMChecked ? 0 : 1; 
              handleCreditMToggle(id, newStatus );
            }}
            color="success"
            inputProps={{ "aria-label": `controlled-${params.value}` }}
          />
        );

      },
    },
  ];
  //=================== This Coloum for All tally companies ================================

  const columns = [
    { field: "id", headerName: "Cmp Id", flex: 1 },

    {
      field: "Cmp_Name",
      headerName: "Company Name",
      // type: "text",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "admin_Id",
      headerName: "admin_Id",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const { id, Cmp_Name } = params.row; // Get the row id
        const isSelected = localStorage.getItem("cmpid") === id;
        const selectedName = localStorage.getItem("cmpname") === Cmp_Name; // Check if this row is selected

        return (
          <div>
            <Button
              variant="contained"
              style={{ backgroundColor: colors.blueAccent[700] }}
              size="small"
              type="button"
              onClick={() => handleSelect(id, Cmp_Name)} 
            >
              {isSelected ? "Selected" : "Select"} 
            </Button>
          </div>
        );
      },
    },
  ];


  // module list view box  code ==============================
  const [open, setOpen] = useState(false); 

  const handleOpen = () => setOpen(true); 
  const handleClose = () => setOpen(false); 
  //==========================================================

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "600px", 
            maxWidth: "80%", 
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "24px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "15px",
            padding: "0 16px",
          }}
        >
          Company module configuration  
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              height: "400px", 
            }}
          >
            <Box
              height="74vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                  color: colors.primary[150],
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                  color: colors.primary[150],
                },
              }}
            >
           
              <DataGrid
                rows={ModuleRow}
                columns={Modulecolumns}
                pageSize={5} 
                rowsPerPageOptions={[5]} // Options for rows per page
                disableSelectionOnClick 
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

    
      {/* //================================================================================================ */}
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Header title="Tally Companies" subtitle="All Tally Companies List" />
          <Button
            variant="contained"
            size="small"
            sx={{
              backgroundColor: colors.blueAccent[700],
            }}
            type="button"
            onClick={handleOpen}
          >
            Add Module
          </Button>
        </Box>

        <Box
   
          height="74vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700], // add color  table coloum header
              borderBottom: "none",
              color: colors.primary[150],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
          }}
        >
          <DataGrid rows={userListRow} columns={columns} />
        </Box>
      </Box>
    </>
  );
}
