import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Header from "../../components/Header";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";


const SecVchApprov = () => {
  //states and local Variable
  const token = localStorage.getItem("token");
  const cmpid = localStorage.getItem("cmpid");
  const [SecVchs, setVouchers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]); 
  const [rejectId , setrejectId] =useState("");
  const [rejectReason , setrejectReason] =useState("");

  //call afeter Component is load 
  useEffect(() => {
    fetchData();
  }, [token]);

  //function to fecth Secondary Approval Vouchers
  const fetchData = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/secondaryList",
        { cmpid },
        {
          headers: { Authorization: token },
        }
      );
      const SecVcharrayData = Object.values(response.data);
      setVouchers(SecVcharrayData);
    } catch (error) {
      console.error("Error fetching data", error)
    }
  };

   
 
const hnadleReject = (id)=> {
  setrejectId(id)
  setOpen(true); 
}


  //call on approve button to handle approval and reject 
  const handleApproval = (id, status) => {
   const rejectionremark= rejectReason
    axios .post(
        `http://64.227.138.8/approvalModule/secondaryUpdate`,
        { id, status , rejectionremark },
        {
          headers: { Authorization: token },
        }
      )
      .then((response) => {
        setVouchers(SecVchs.filter((SecVch) => SecVch.vch_id !== id));
      })
      .catch((error) => {
        console.error("Error updating voucher status:", error);
      });

    if (status == 1) {
      alert("Approve SuccessFully");
      fetchData();
    } else {
      alert("Rejected...");
      fetchData(); 
      setrejectReason("")
      handleClose()
     
    }
  };



  //Handle to bulk aprroval and reject 
  const handleApprovalAll = (status) => {
    const selectedVoucherIds = SecRows
      .filter((SecRows) => selectedIds.includes(SecRows.id))
      .map((SecRows) => SecRows.id);

    const formattedArray = selectedVoucherIds.map(id => ({ id, status }));
    console.log("formattedArray : ", formattedArray[0])
    try {
      axios.post(
        process.env.REACT_APP_API_URL+"/approvalModule/allSecondaryUpdate", formattedArray,

        {
          headers: { Authorization: token },
        }
      );
      if (status === 1) {
        alert("Aprroved Suucess")
      } else {
        alert("Rejected Suucess")
      }

      fetchData();
   } catch (error) {
      console.error("Error fetching data", error);
    }
  };



  //rows for approval Voucher table 
  const SecRows = Array.isArray(SecVchs)
    ? SecVchs.map((SecVch) => ({
      id: SecVch.vch_id,
      vchDate: SecVch.vch_date,
      partyName: SecVch.PartyName,
      vchNumber: SecVch.vch_number,
      vchTypes: SecVch.vch_type,
      vchAmount: SecVch.vch_Amount,
    }))
    : [];


  //columns for approval Voucher table 
  const SecColumns = [
    {
      field: "Sr No",
      headerName: "Sr No",
      flex: 1,
      renderCell: (params) => params.api.getRowIndex(params.id) + 1,
    },
    { field: "vchNumber", headerName: "vch_number", flex: 1 },
    { field: "vchTypes", headerName: "vch_Type", flex: 1 },
    { field: "vchDate", headerName: "vch_date", flex: 1 },
    { field: "partyName", headerName: "PartyName ", flex: 1 },
    { field: "vchAmount", headerName: "vch_Amount", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 2,
      renderCell: (params) => (
        <div>
          <Button
            type="button"
            variant="contained"
            color="success"
            size="small"
            startIcon={<VerifiedIcon />}
            onClick={() => handleApproval(params.id, "1")}
            style={{ marginRight: "10px" }}
          >  Approve </Button>



          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<CancelRoundedIcon />}
            type="button"
            onClick={() => hnadleReject(params.id)}
          > Reject  </Button>

        </div>
      ),
    },
  ];

  //add reject reason Popup Form  
  //========================================================
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState();

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "400px",
              maxWidth: "50%",
              height: "215px",

            },
          }}
        >
          <DialogTitle variant="h3">Add Reason for Reject</DialogTitle>

          <DialogContent>
            <Box component="form">
              <TextField
                size="small"
                variant="outlined" 
                label="Enter Reason" 
                fullWidth 
                margin="normal"  
                value={rejectReason}
                onChange={(event) => setrejectReason(event.target.value)}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => handleApproval(rejectId ,"0")}
              sx={{
                backgroundColor: colors.blueAccent[700],
                mb: 2,
                mr: 2,
               }}
              variant="contained"
              size="small" 
            
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* =================================================== */}


      {/*Ḥeaders for Table   */}
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Header positioned on the left */}
          <Header
            title="Voucher Approval"
            subtitle="Secondary Voucher Approval"
          />

      {/* ApprovalAll And RejectAll Buttons */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button variant="contained" color="success" size="small" onClick={() => handleApprovalAll(1)}>
              Approve All
            </Button>
            <Button variant="contained" color="error" size="small" onClick={() => handleApprovalAll(0)}>
              Reject All
            </Button>
          </Box>
        </Box> 

        {/* Vouchers List Table  */}

        <Box
          height="74vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              color: colors.primary[150],
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
              color: colors.primary[150],
            },
            "& .MuiDataGrid-cell:focus": 
            { outline: "none" },
          }}
        >
          <DataGrid
            rows={SecRows}
            columns={SecColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={(newSelection) => setSelectedIds(newSelection)}
          />
        </Box>
      </Box>
    </>
  );
};

export default SecVchApprov;
